.appt-list-picker {
  .ant-picker-input {
    input::placeholder{
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 400 !important;
        color: rgb(73 80 87) !important;
        font-size: 14px !important;
        padding: 3px 0;
    }
    input{
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 400 !important;
        color: rgb(73 80 87) !important;
        font-size: 14px !important;
        padding: 3px 0;
    }
   
  }
}
