* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.css-7hrgf6 {
  width: 100% !important;
}

body {
  list-style: none;
}

.editoCss {
  margin: 10px 23px 25px;
  border: 1px solid #f1f1f1;
}

.editoCss.editBlog {
  margin: 0px;
  border: 1px solid #f1f1f1;
}

.editoCss .DraftEditor-root {
  padding: 0 17px;
}

.theme_btn {
  background-color: #ac0047 !important;
  color: #fff !important;
  width: 100%;
}

.CntForm {
  width: 60%;
  margin: 40px auto;
}

.faqTable table tbody tr td:nth-child(2) span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 400px;
  line-height: normal;
}

.faqTable table tbody tr td span a:nth-of-type(1) {
  margin-right: 8px;
}

.faqTable table tbody tr td span a:nth-of-type(2) {
  margin-right: 8px;
}

textarea {
  width: 100% !important;
  height: auto !important;
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  border: 0.0625rem solid #d2d6da;
  appearance: none !important;
  border-radius: 0.5rem;
  -webkit-transition: box-shadow 150ms ease, border-color 150ms ease,
    padding 150ms ease !important;
  transition: box-shadow 150ms ease, border-color 150ms ease, padding 150ms ease !important;
  background-color: #ffffff;
  pointer-events: auto;
}

textarea:focus-visible {
  border-color: #ff8fb0;
  box-shadow: 0rem 0rem 0rem 0.125rem rgb(249, 129, 215);
  outline: 0;
}

.filter svg {
  display: block !important;
}

.table_header {
  gap: 10px !important;
}

.table_header .filter {
  width: unset !important;
}

.appointment_form .text-danger {
  color: #ea0606;
}

.img-responsive {
  max-width: 100%;
  height: auto;
}

.css-el4dv2-MuiPaper-root {
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
  padding: 10px !important;
  height: 70px !important;
  overflow: hidden !important;
}

.service_data {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
}

/* --table--- */

table tbody td span a.MuiTypography-caption
 {
  padding: 5px 10px;
  background-color: #ff6bb5;
  border-radius: 5px;
  color: #fff;
}

table tbody td span a.disabled {
  color: #fff;
  cursor: alias;
  background-color: #a3a3a3;
}



--
/* SuiTagInput.css */

.user_treatment {
  position: relative !important;
}

.user_treatment button.download-btn {
  position: absolute;
  top: 41px;
  right: 10px;
  z-index: 9999;
}

.user_treatment .sui-tag-container {
  border: 0.25px solid #d2d6da;
  flex: 1;
  padding: 3px 10px;
  border-radius: 10px;
  width: 100%;
  border: 0.0625rem solid #d2d6da;
  display: flex;
  gap: 5px;
}

/* ---common-image-upload--- */

.upload-image {
  width: 200px;
  height: auto;
  margin: auto;
  max-width: 200px;
  position: relative;
  display: grid;
  place-content: center;
}

.upload-image label {
  height: 40px;
  width: 40px;
  display: grid;
  place-content: center;
  background-color: #ff8fb0;
  border-radius: 50%;
  position: absolute;
  left: 85px;
  bottom: -10px;
  color: #fff;
}

.upload-image input {
  display: none;
}

.upload-image img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
}

.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.icon-cntner svg {
  font-size: 30px;
  cursor: pointer;
}

.imgUploaderContainer {
  border: 1px solid;
  padding: 14px;
  text-align: center;
  border: 0.0625rem solid #d2d6da;
  border-radius: 0.5rem;
  transition: box-shadow 150ms ease, border-color 150ms ease, padding 150ms ease !important;
  height: 60px;
  margin-bottom: 20px;
}

.imgUploaderContainer p {
  font-size: 16px;
}

.imgUploaderContainer .image-preview {
  padding: 20px;
}

.image-preview .image-list {
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

.image-list .image-item {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: relative;
  margin-left: 10px;
}

.image-item .close {
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 5px;
}

/* --Add-Provoders-- */

.third_Step .MuiPaper-rounded div li {
  display: inline-block;
}

.dentist_profile_cntner .profile_edit {
  background-color: #ff8fb0;
  height: 30px;
  width: 30px;
  text-align: center;
  display: grid;
  place-content: center;
  border-radius: 50%;
  opacity: 0;
  position: absolute;
  top: 10px;
  right: 20px;
  transition: 0.2s all;
  cursor: pointer;
}

.dentist_profile_cntner .profile_edit svg {
  color: #fff;
}

.dentist_profile_cntner:hover .profile_edit {
  opacity: 1;
}

/* --Home-Page-Management-- */

.Image_Wrappr .Image_Wrappr_inner {
  width: 400px;
  height: 300px;
  max-width: 400px;
  position: relative;
  display: grid;
  place-content: center;
  overflow: hidden;
  border-radius: 10px;
}

.Image_Wrappr .Image_Wrappr_inner::after {
  content: "";
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.Image_Wrappr .Image_Wrappr_inner input {
  display: none;
}

.Image_Wrappr .Image_Wrappr_inner label {
  position: absolute;
  background-color: #344767;
  height: 50px;
  width: 50px;
  border-radius: 10px;
  display: grid;
  place-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: 0.4s all;
  cursor: pointer;
}

.Image_Wrappr .Image_Wrappr_inner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Image_Wrappr .Image_Wrappr_inner label svg {
  color: #fff;
  font-size: 30px;
}

.Image_Wrappr .Image_Wrappr_inner:hover label {
  opacity: 1;
  z-index: 9;
}

.Image_Wrappr .Image_Wrappr_inner:hover ::after {
  opacity: 0;
}

.Image_Wrappr .Image_Wrappr_inner .image-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.question_cntainer tbody td .data {
  white-space: nowrap;
  width: 19rem;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 400;
}

/* --Support Management--- */

.banner_cntainer .Image_Wrappr .Image_Wrappr_inner {
  width: 100%;
  max-width: 100%;
}

.banner_container:hover .upload-label {
  opacity: 1;
  z-index: 9999;
}

.banner_container label.upload-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  display: grid;
  place-content: center;
  background: #ff8fb0;
  border-radius: 8px;
  color: #fff;
  opacity: 0;
}

.provider_slider {
  padding-right: 15px;
}

.slick-prev:before,
.slick-next:before {
  color: #ff8fb0 !important;
}

.form-error {
  color: red;
  font-size: 0.9rem;
  text-align: start;
  margin-top: 2px;
}

.form-error1 {
  color: red;
  font-size: 0.9rem;
  text-align: center;
  margin-top: 2px;
}

/* --datepicker--- */

.react-datepicker__input-container input {
  border-radius: 0.5rem;
  padding: 0.8rem 0.75rem !important;
  border: 0.0625rem solid #d2d6da;
  appearance: none;
}

.react-datepicker-popper {
  z-index: 2 !important;
}

::placeholder {
  color: #8392ab;
  opacity: 1;
}

::-ms-input-placeholder {
  /* Edge 12-18 */
  color: #8392ab;
}

.react-datepicker__input-container input:focus-visible {
  border-color: #ff8fb0 !important;
  box-shadow: 0rem 0rem 0rem 0.125rem rgb(249, 129, 215) !important;
  outline: 0;
}

::placeholder {
  color: #000 !important;
}

.slots_availability {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.slots_availability .active {
  background-color: #ac0047 !important;
}

/* --profile-- */

.edit-profile-page {
  padding: 20px;
}

.edit-profile-page .form-group label {
  font-size: 16px;
}

.edit-profile-page .image-upload {
  position: relative;
  display: flex;
  align-items: center;
}

.edit-profile-page .upload-btn {
  padding: 5px 10px;
  border: 1px solid #344767;
  margin-left: 10px;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 0.5rem;
  box-shadow: -2rem 0rem 10rem 7rem rgba(0, 0, 0, 0.05);
  cursor: pointer;
}

.edit-profile-page .image-upload img {
  height: 100px;
  width: 100px;
  border-radius: 6px;
  object-fit: cover;
}

.testimonial-img {
  width: 100% !important;
  height: 100% !important;
  margin-bottom: 0 !important;
}

.testimonial-content {
  padding: 10px 8px;
}

.testimonial-content label {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  font-size: 14px;
  color: #6c6565;
  line-height: 26px;
}

.testimonial-content .testi-name label {
  color: #000;
  text-align: center;
}

.testimonial-content .author-name label {
  font-size: 20px !important;
  color: #cb0c9f;
  text-align: center;
}

.testi-modal .css-1wnsr1i {
  width: 600px !important;
}

.testi-modal .Image_Wrappr {
  display: flex;
  justify-content: center;
}

.testi-modal .Image_Wrappr .Image_Wrappr_inner {
  width: 200px !important;
  height: 200px !important;
}

.testi-modal .MuiBox-root.css-ggmxdf {
  margin-bottom: 0;
}

.clsBtn {
  position: absolute;
  top: 15px;
  right: 10px;
}

.clsBtn svg {
  width: 30px;
  height: 30px;
  color: #8f201b;
  cursor: pointer;
}

.edtBtn {
  position: absolute;
  top: 15px;
  right: 45px;
}

.edtBtn svg {
  width: 30px;
  height: 30px;
  color: #8f201b;
  cursor: pointer;
}

.Image_Wrappr {
  position: relative;
}

/* User searches style 25 Jan */

.detailItem p {
  font-size: 16px;
  font-weight: 400;
}

.qsnWrap p {
  font-size: 16px;
  font-weight: 400;
}

.usrSearchHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
  width: 100% !important;
}

.selctServc {
  margin-bottom: 1rem;
}

.bookingPaymentSt svg {
  width: 1.1rem;
  height: 1.1rem;
}

/* .pagi {
  display: flex;
  width: 100%;
  justify-content: end;
}

.pagi .css-1ui3wbn-MuiInputBase-root-MuiTablePagination-select {
    width: auto !important;
} */

.css-jtlhu6-MuiTablePagination-root .MuiInputBase-root {
  width: 5rem !important;
  max-width: 5rem !important;
}

.MuiTablePagination-root .MuiInputBase-root {
  width: 5rem !important;
  max-width: 5rem !important;
}

.MuiTablePagination-root p {
  margin-bottom: 0 !important;
}

.ad-adrs-input {
  height: 40px;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #c1c1c1;
  padding: 10px;
}

.pac-container {
  z-index: 99999;
}

.MuiCardMedia-media {
  height: 200px !important;
  width: 100% !important;
  object-fit: cover !important;
}

/* ----time-slot-manage--- */

.timeSlotsContainer {
  margin: 20px 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  grid-gap: 10px;
}

.time-slot {
  background-color: #fff;
  box-shadow: -1.5px 4.5px 20px rgba(0, 0, 0, 0.08), 89px 89px 94px rgba(0, 0, 0, 0.09);
  cursor: pointer;
  text-align: center;
  border-radius: 8px;
}

.time-slot input[type="checkbox"] {
  display: none;
}

.time-slot input[type="checkbox"]:checked + label {
  background: #ffffff;
  border: 1px solid #ff47d4;
  color: #000000;

}

.time-slot label {
  display: block;
  cursor: pointer;
  font-size: 17px;
  padding: 6px;
  border-radius: 8px;
}

.enq-btn{
  width: 40%;
  margin-left: 10px;
}




.clinicCharger-table table td {
  font-size: 0.875rem;
  color: #8392ab;

}

.clinicCharger-table table thead {
  display: table-header-group;
}


.clinicCharger-table table th {
  font-size: 0.65rem;
  font-weight: 700;
  color: #8392ab;
  text-transform: uppercase;
}

/* UserTable.css */
.reviewDescription {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


.ant-empty {
  min-height: 250px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}